<template>
  <div>
    <div
      v-if="isShow"
      :id="'reserve-block' + keyNo"
      :class="['sc-bar', getClass(scheduleDetail), isAppointment(scheduleDetail)]"
      :style="styleObject"
      :draggable="scheduleDetail.assignment.assignmentID === 1"
      @dragstart="moveStart"
      @drag="moved"
      @dragend="moveEnd"
      @click="$emit('click-event')"
      @contextmenu.capture.prevent="contextMenu($event, scheduleDetail)"
    >
      <div
        class="header"
        @click="goToAction(scheduleDetail.actionID)"
      >{{ scheduleDetail.actionFileNumber }}</div>
      <div
        :id="'counter-block' + keyNo"
        class="counter-index"
      >
        <div
          v-if="scheduleDetail.mustGo"
          class="checked-img-wrapper"
        >
          <img
            class="checked-img"
            src="../../assets/images/icons/security-interface.png"
            alt="security"
          >
        </div>
        <div
          v-if="!scheduleDetail.mustGo && scheduleDetail.hold"
          class="checked-img-wrapper"
        >
          <img
            class="checked-img"
            src="../../assets/images/icons/pause-button.png"
            alt="pause"
          >
        </div>
        <div v-if="index && occupied && occupied.length > 1">
          {{ occupied.length }}
          <div
            class="multiple-list"
            :class="startLineNo === 6 ? 'right' : ''"
          >
            <div
              v-for="(schedule, keyNumber) in occupied"
              :key="keyNumber"
            >
              <div
                class="header"
                @click="changeIndex(schedule.start, schedule.assignment.investigatorID, schedule.actionID)"
                @contextmenu.capture.prevent="contextMenu($event, schedule)"
              >
                {{ schedule.actionFileNumber }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text truncate"
        :style="[styles.text, {'width': styleObject.width}]"
      >
        {{ scheduleDetail.subject }}
        <span v-if="getCurrentScopes.indexOf('st2.viewcalculatedtravel') !== -1">
          {{ formatMiles(scheduleDetail.assignment.distanceToMiles) }}
        </span>
      </div>
      <div
        class="text truncate"
        :style="[styles.text, {'width': styleObject.width}]"
      >
        {{ scheduleDetail.actionType }}
      </div>
      <div
        class="text truncate"
        :style="[styles.text, {'width': styleObject.width}]"
      >
        {{ dateFormat(scheduleDetail.caseDueDate) }}
      </div>
    </div>
    <ScheduleContextMenu
      v-if="showContext && ContextScheduleDetail.isEditable && (actionID ? actionID === scheduleDetail.actionID : true) && !scheduleDetail.isClosed"
      :option="ContextMenuOption"
      @click="showContext = false"
      @click-outside="showContext = false"
      @cancel-assignment="cancelAssignment"
      @change-assignment="changeAssignment(ContextScheduleDetail)"
      @multiple-assignment="multipleAssignment(ContextScheduleDetail)"
    />
  </div>
</template>

<script>

import SchedulesService from "@/views/schedule/assetsSchedules";
import ScheduleContextMenu from "@/views/schedule/ScheduleContextMenu";
import {mapGetters} from "vuex";

const ScheduleService = new SchedulesService();
export default {
  components: {
    ScheduleContextMenu,
  },
  props: {
    rowIndex: Number,
    actionID: Number,
    index: Number,
    keyNo: Number,
    unitWidth: Number,
    borderWidth: Number,
    startText: String,
    endText: String,
    minDate: String,
    unit: Number,
    dragenterRowIndex: Number,
    dragenterColumnIndex: Number,
    scheduleDetail: Object,
    occupied: Array,
    rowDetail: Object,
  },
  data: function() {
    return {
      styleObject: {
        Opacity: 1,
        padding: "0.2rem",
        left: "0px",
        width: "0vw",
        height: "5rem",
        margin: "3px",
        top: "0",
        zIndex: "0"
      },
      ContextMenuOption: {
        X: 0,
        Y: 0,
      },
      ContextScheduleDetail: null,
      showContext: false,
      isShow: false,
      mouseXStarted: null,
      startLineNo: null,
      endLineNo: null,
      isMe: false,
      isMove: false,
      styles: {
        text: {
          fontSize: "0.8rem",
          height: "auto",
          overflow: "auto"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    })
  },
  watch: {
    startText(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.startDate = newVal;
        this.setLeftPosition();
      }
    },
    endText(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.endDate = newVal;
        this.setWidth();
      }
    },
    index(val) {
      this.styleObject.zIndex = val;
    },
  },
  created() {
    this.styleObject.zIndex = this.index;
    if (new Date(this.startText) - new Date(this.minDate) < 0 &&
        new Date(this.endText) - new Date(this.minDate) < 0) {
      return;
    }
    this.setLeftPosition();
    this.setWidth();
    this.isShow = true;
  },
  mounted() {
      this.hover()
  },
  methods: {
    hover() {
      const element = document.querySelector("#counter-block" + this.keyNo)
      const reserveBlock = document.querySelector("#reserve-block" + this.keyNo)
      element.addEventListener('mouseenter', e => {
          reserveBlock.classList.add("top")
      })
      element.addEventListener('mouseleave', e => {
          reserveBlock.classList.remove("top")
      })
    },

    setLeftPosition() {
      let shiftCnt = this.getItemColumn() - 1;
      this.startLineNo = shiftCnt;
      let shiftLeft = this.unitWidth * shiftCnt * this.borderWidth;
      this.styleObject.left = shiftLeft + "vw";
    },

    setWidth() {
      let widthCnt = 1;
      this.endLineNo = this.startLineNo + widthCnt;
      let width = this.unitWidth * widthCnt * this.borderWidth;
      this.styleObject.width = (width - 0.7) + "vw";
    },

    moveStart(e) {
      this.styleObject.Opacity = 0.5;
      this.isMove = true;
      this.mouseXStarted = e.clientX;
      this.$emit("set-dragenter-row-and-index", this.rowIndex, this.getItemColumn());
    },

    moved(e) {
      const parentBox = document.getElementById("table-block");
      const way = (e.clientX && (e.clientX < parentBox.getBoundingClientRect().left)) ? "left" :
          (e.clientX && (e.clientX > (parentBox.getBoundingClientRect().left + parentBox.offsetWidth))) ? "right" : null;
      if (way) {
        this.$emit("drag-change-week", way, this.rowIndex, this.keyNo);
      }
    },

    moveEnd(e) {
      let mouseXEnd = e.clientX;
      if (this.isMove && (mouseXEnd !== this.mouseXStarted ||
          this.dragenterRowIndex !== this.rowIndex)) {
        this.mouseXStarted = null;
        const unitCnt = this.getUnitCnt(mouseXEnd);
        if (this.dragenterColumnIndex !== null && (unitCnt !== 0 || (this.dragenterRowIndex !== this.rowIndex))) {
          this.$emit("move-schedule-data", this.rowIndex, this.keyNo, unitCnt);
        }
      }
      this.isMove = false;
      this.styleObject.Opacity = 1;
    },

    getUnitCnt(mouseXEnd) {
      const parentBox = document.getElementById("table-block");
      const itemWidth = parentBox.offsetWidth / 7;
      const countTarget = mouseXEnd - parentBox.getBoundingClientRect().left
      const count = parseInt(countTarget / itemWidth) + 1;
      return count > 7 ? 7 : count;
    },

    getItemColumn() {
      let leftDiff = ScheduleService.getMinutesDiff(
          new Date(this.minDate),
          new Date(this.startText)
      );
      return parseInt(leftDiff / this.unit) + 1;
    },

    goToAction(actionID) {
      const url = '/cases/edit-action/' + actionID;
      window.open(url, '_blank')
    },

    changeIndex(start, investigatorID, actionID) {
        const reserveBlock = document.querySelector("#reserve-block" + this.keyNo)
        if (actionID !== this.scheduleDetail.actionID) {
            reserveBlock.classList.remove("top")
            this.$emit("change-index", start, investigatorID, actionID)
        } else {
            reserveBlock.classList.add("top")
        }
    },

    getStyleObject() {
      const styleObject = this.styleObject;
      styleObject.top = this.scheduleDetail.index + "rem";
      return styleObject;
    },

    dateFormat(val) {
      return ScheduleService.dateFormat(val) || "";
    },

    contextMenu(e, scheduleDetail) {
      e.preventDefault()
      setTimeout(() => {
        this.ContextScheduleDetail = scheduleDetail;
        this.ContextMenuOption.X = e.clientX;
        this.ContextMenuOption.Y = e.clientY;
        this.ContextMenuOption.isNew = scheduleDetail.assignment.assignmentID === 1;
        this.ContextMenuOption.actionId = scheduleDetail.actionID;
        this.ContextMenuOption.scheduledDate = scheduleDetail.start.substring(0, 10);
        this.ContextMenuOption.investigatorId = this.rowDetail.value;
        this.ContextMenuOption.isDouble = scheduleDetail.isDouble;
        this.ContextMenuOption.previousInvestigatorId = scheduleDetail.previousInvestigatorId;
        this.ContextMenuOption.reAssignment = scheduleDetail.reAssignment;
        this.ContextMenuOption.isChangingDate = scheduleDetail.isChangingDate;
        this.showContext = true
      })
    },

    cancelAssignment() {
      this.showContext = false;
      this.$emit('cancel-assignment');
    },

    changeAssignment(scheduleDetail) {
      this.showContext = false;
      this.$emit('change-assignment', scheduleDetail, this.rowDetail.value);
    },

    multipleAssignment(scheduleDetail) {
      this.showContext = false;
      this.$emit('multiple-assignment', scheduleDetail, this.rowDetail);
    },

    getClass(val) {
      if (val.assignment.assignmentID === 1) {
        return 'isNew'
      } else if (val.isClosed) {
        return "isClosed";
      } else if (val.isDouble) {
        return "isDouble";
      } else {
        return 'isMe'
      }
    },

    isAppointment(val) {
      if (val.actionType === "Surveillance (Appointment)" || val.actionType === "Surveillance - Two Investigators (Appointment)") return "appointment";
      return "";
    },

    formatMiles(value) {
      if (value && value > 1) {
        return " (" + Math.floor(value).toString() + ")";
      } else {
        return ""
      }
    }
  },
}
</script>

<style scoped>
@import "schedule.css";
</style>