<template>
  <div
    class="context-menu"
    :style="getPosition()"
  >
    <div v-if="option.isNew">
      <div
        class="menu-item"
        @click="dateChecking"
      >Assign</div>
      <hr class="m-0"/>
      <div
        class="menu-item"
        @click="CancelAssignment"
      >Cancel Assignment</div>
    </div>

    <div v-else>
      <div
        class="menu-item"
        @click="modalUnassign"
      >Unassign</div>
      <hr class="m-0"/>
      <div
        class="menu-item"
        @click="ChangeAssignment"
      >Change Assignment</div>
      <div>
        <hr class="m-0"/>
        <div
          class="menu-item"
          @click="MultipleAssignment"
        >Create Multiple Assignment</div>
      </div>
    </div>

    <b-modal
      id="modal-past-date-check"
      ok-variant="danger"
      ok-title="Ok"
      cancel-title="Cancel"
      modal-class="modal-danger"
      hide-header-close
      centered
      title="Confirm Assigning"
      @ok="Assign"
      @cancel="cancelAssign"
    >
      <div>You are assigning a Scheduled Date in the past.  Please confirm this is your intent.</div>
    </b-modal>

    <b-modal
      id="modal-assigning"
      ok-variant="danger"
      ok-title="Ok"
      cancel-title="Cancel"
      modal-class="modal-danger"
      hide-header-close
      centered
      title="Сonfirm Assigning"
      @ok="confirmAssign"
      @cancel="cancelAssign"
    >
      <div>Changing the assignment cannot be undone.  Proceed?</div>
    </b-modal>

    <b-modal
      id="modal-unassign"
      ok-variant="danger"
      ok-title="Ok"
      cancel-title="Cancel"
      modal-class="modal-danger"
      hide-header-close
      centered
      title="Сonfirm Unassign"
      @ok="confirmUnassign"
      @cancel="cancelUnassign"
    >
      <div>Unassignments cannot be undone.  Proceed?</div>
    </b-modal>
  </div>
</template>

<script>

import APIService from "@core/utils/APIService";
import SchedulesService from "@/views/schedule/assetsSchedules";
import {BModal} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const apiService = new APIService();
const ScheduleService = new SchedulesService();

export default {
  name: "ScheduleContextMenu",
  components: {
    BModal,
  },
  props: {
    option: Object,
  },
  created() {
    window.addEventListener('contextmenu', (e) => {
      if (!this.$el.contains(e.target)) {
        this.$emit('click-outside');
      }
    })
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target) && (e.target.parentElement.className !== "modal-footer" ||
          (e.target.parentElement.className === "modal-footer" && e.target.textContent === "Cancel"))) {
        this.$emit('click-outside');
      }
    })
  },
  methods: {
    showToast(variant, position, timeout, data) {
        this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Notification',
                    icon: 'InfoIcon',
                    text: data,
                    variant,
                },
            },
            {
                position,
                timeout,
            })
    },

    Assign() {
        if (this.option.reAssignment) {
            this.$bvModal.show("modal-assigning")
        } else {
            this.confirmAssign();
        }
    },

    dateChecking() {
        if (this.option.isChangingDate &&
            new Date(this.option.scheduledDate).toISOString().substr(0,10) < new Date().toISOString().substr(0,10)) {
            this.$bvModal.show("modal-past-date-check");
        } else {
            this.Assign()
        }
    },

    confirmAssign() {
      let path = "action/assign/scheduled-data";
      let postData = {
        "actionId": this.option.actionId,
        "scheduledDate": ScheduleService.dateParser(this.option.scheduledDate),
        "investigatorId": this.option.investigatorId,
      }
      if (this.option.previousInvestigatorId) {
        path = "action/reassign/scheduled-data"
        postData = {...postData, previousInvestigatorId: this.option.previousInvestigatorId}
      }
      apiService.post(path, postData).then(res => {
          if (res && res.code && res.code === 304) {
              this.showToast('danger', 'top-center', 4000, JSON.stringify('Operation resulted in no change to original data.'))
          }
        if (!res.data.success) {
          this.showToast('warning', 'top-center', 5000, res.data.message);
        }
        this.$emit('cancel-assignment');
      })
    },

    modalUnassign() {
      this.$bvModal.show("modal-unassign");
    },

    confirmUnassign() {
      this.Unassign()
    },

    cancelAssign() {
      this.$bvModal.hide("modal-past-date-check");
    },

    cancelUnassign() {
      this.$bvModal.hide("modal-unassign");
    },

    CancelAssignment() {
      this.$emit('cancel-assignment');
    },

    Unassign() {
      const postData = {
        "actionId": this.option.actionId,
        "investigatorId": this.option.investigatorId,
      }
      apiService
          .post("schedule/unassign", postData)
          .then(() => {
            this.$emit('cancel-assignment');
            this.cancelUnassign();
          })
    },

    ChangeAssignment() {
      this.$emit('change-assignment');
    },

    MultipleAssignment() {
      this.$emit('multiple-assignment')
    },

    getPosition() {
      const pos = window.innerHeight;
      const top = ((pos / 1.3) < this.option.Y) ? this.option.Y - (this.option.isDouble ? 112 : 77) : this.option.Y;
      return {top: top + 'px', left: this.option.X + 'px'}
    }
  }
}
</script>

<style scoped>
.dark-layout .context-menu {
  background: #4d5061;
}
.context-menu {
  position: fixed;
  border: 1px solid;
  background: ghostwhite;
  white-space: nowrap;
  z-index: 250;
}
.menu-item {
  cursor: pointer;
  padding: 0.5rem;
}
</style>