<template>
  <ManagerSchedulesTable
    @row-click-event="rowClickEvent"
  />
</template>

<script>

import ManagerSchedulesTable from "@/views/schedule/ManagerSchedulesTable";

export default {
  components: {
    ManagerSchedulesTable,
  },
  methods: {
    rowClickEvent(user) {
      let route = this.$router.resolve('/assignments/user/' + user.value);
      window.open(route.href, '_blank');
    },
  }
};
</script>

<style>
</style>